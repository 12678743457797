import React, { useState, useEffect } from 'react'
import cn from 'classnames'

import CustomUploadPanel from './dashboard_tiles/CustomUploadPanel.js'
import NewNDReportPanel from './dashboard_tiles/NewNDReportPanel.js'
import RecentHistoryPanel from './dashboard_tiles/ReportHistoryPanel.js'
import RecentClassifiersPanel from './dashboard_tiles/RecentClassifiersPanel.js'
import SpeedyReportForOrgPanel from './dashboard_tiles/SpeedyReportForOrgPanel.js'
import AlertSubscriptionsPanel from './dashboard_tiles/AlertSubscriptionsPanel.js'
import NewSetTheoryReportPanel from './dashboard_tiles/NewSetTheoryReportPanel.tsx'
import CustomBuilderTile from './dashboard_tiles/CustomBuilderTile.js'

import Grid from './widgets/Grid.js'
import GridElement from './widgets/GridElement.js'
import { ExternalTextLink } from './widgets/ExternalTextLink.js'
import { withUser } from './UserContext.js'
import {
  is_creator,
  has_classifiers_edit,
  has_cipher_product,
  is_poweruser,
  has_set_theory_service,
  has_any_cipher_product,
  has_hs_google_valuation,
  has_quick_org_search,
  is_view_only_user,
  has_text_to_nearest
} from '../utils/user_permissions.js'

import { PRODUCT_ND } from '../constants/cipher_product_ids.js'

//welcome tour tags that work as element classes 
import {
  WELCOME_TAG,
  QUICK_ORGANISATION_SEARCH_TAG,
  CUSTOM_CLASSIFIERS_TAG,
  ALERT_SUBSCRIPTIONS_TAG,
  N_D_TAG,
  CUSTOM_UPLOAD_TAG,
  SET_THEORY_TAG,
  GOOGLE_VALUATION_TAG,
  MULTI_SEARCH_TAG
} from '../model/welcome_tour.js'
import { track_visit_event } from '../utils/tracking_utils.js'
import { get_user_classifiers_groups } from '../utils/classifier_group_utils.js'
import NewGoogleValuationReportPanel from './dashboard_tiles/NewGoogleValuationReportPanel.js'
import MultiSearchPanel from './dashboard_tiles/MultiSearchPanel.js'
import { RegisteredSymbol } from './widgets/TrademarkSymbol.js'

import cs from './cipher_styles.module.scss'
import s from './Dashboard.module.scss'
import TextToNearestPanel from './dashboard_tiles/TextToNearestPanel.js'

const Dashboard = ({user}) => {
  const [user_built_classifiers, set_user_built_classifiers] = useState(null)

  useEffect(() => {
    document.title = 'Classification: Home'
    track_visit_event('page="dashboard"')
    get_user_classifiers_groups()
      .then(built_classifiers => {
        set_user_built_classifiers(built_classifiers)
      })
  }, [])

  const is_loading_classifiers_list = user_built_classifiers === null
  const user_has_private_classifiers = user_built_classifiers && user_built_classifiers.length > 0
  const user_has_classifiers = user_has_private_classifiers || has_any_cipher_product(user)

  return (
    <div className={s.block}>

      <h1 className={cn('mb-2', cs.white_text)}>
        Welcome to LexisNexis<RegisteredSymbol /> Classification
      </h1>

      { !is_creator(user) &&  //show upsell blurb to Lite users
        <div className={cn('mt-4 mb-3', s.lite_user_message)}>
          <h5 className={cs.white_text}>
            This is a <span>Classification {is_view_only_user(user) ? "'view only'" : 'Lite'} account</span> with limited functionality.
          </h5>
          <div className='mt-1'>
            If you would like to know more about the capabilities of Classification, or would like to upgrade,
            please contact your Administrator or
            <ExternalTextLink
              url='http://cipher.ai/contact/'
              target='_blank'
              title='contact us'
              className={cn('ms-1', cs.white_text)}
            >
              contact us
            </ExternalTextLink>
          </div>
        </div>
      }

      { !is_view_only_user(user) &&
        <div
          className={MULTI_SEARCH_TAG} // tag for welcome tour
        >
          <MultiSearchPanel />
        </div>
      }

      <Grid
        className={cn('mt-3', WELCOME_TAG)} // tag for welcome tour
      >
        { is_creator(user) &&
          <GridElement
          >
            <CustomBuilderTile
              user_has_classifiers={user_has_classifiers}
            />
          </GridElement>
        }

        { has_quick_org_search(user) && !is_view_only_user(user) &&
          <GridElement
            className={QUICK_ORGANISATION_SEARCH_TAG} // tag for welcome tour
          >
            <SpeedyReportForOrgPanel/>
          </GridElement>
        }

        <GridElement>
          <RecentHistoryPanel />
        </GridElement>

        { is_creator(user) && has_classifiers_edit(user) &&
          <GridElement
            className={CUSTOM_CLASSIFIERS_TAG} // tag for welcome tour
          >
            <RecentClassifiersPanel />
          </GridElement>
        }

        {!is_loading_classifiers_list &&
          <GridElement
            className={ALERT_SUBSCRIPTIONS_TAG} // tag for welcome tour
          >
            <AlertSubscriptionsPanel user_built_classifiers={user_built_classifiers} />
          </GridElement>
        }


        { has_cipher_product(user, PRODUCT_ND) && !is_view_only_user(user) &&
          <GridElement
            className={N_D_TAG} // tag for welcome tour
          >
            <NewNDReportPanel />
          </GridElement>
        }

        { is_creator(user) && has_hs_google_valuation(user) &&
          <GridElement
            className={GOOGLE_VALUATION_TAG} // tag for welcome tour
          >
            <NewGoogleValuationReportPanel />
          </GridElement>
        }

        { has_text_to_nearest(user) &&
          <GridElement>
            <TextToNearestPanel />
          </GridElement>
        }

        { is_creator(user) && is_poweruser(user) &&
          <GridElement
            className={CUSTOM_UPLOAD_TAG} // tag for welcome tour
          >
            <CustomUploadPanel />
          </GridElement>
        }

        { is_creator(user) && has_set_theory_service(user) &&
          <GridElement
            className={SET_THEORY_TAG}
          >
            <NewSetTheoryReportPanel />
          </GridElement>
        }

      </Grid>
    </div>
  )
}

export default withUser(Dashboard)