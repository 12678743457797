import axios from 'axios'

import { USER_MANAGEMENT_BASE_URL } from '../constants/urls.js'
import { add_source_err_to_target_err, JSON_POST_HEADER } from './axios_utils.js'

export const KEYCLOAK_ROLES_TO_EXCLUDE = [
  'default-roles-cipher',
  'offline_access',
  'uma_authorization',
  'has_view_admin_page',
  'has_view_create_users_page',
  'is_admin',
  'is_cipher_engineering',
  //'is_classifiers_admin',
  //'require_otp',
  'is_sso_user',
  'has_patentsight_sync_classifiers',
  'has_assignee_grouping',
  'is_solutions',
  'has_hs_google_valuation',
]

export const KEYCLOAK_ROLES_TO_EXCLUDE_AT_USER_LEVEL = [
  'is_academic',
  'is_other',
  'is_strategic_partner',
  'is_subscriber',
  'is_trial'
]

export const ACCOUNT_TYPE_ROLES = [
  'is_academic',
  'is_internal',
  'is_other',
  'is_strategic_partner',
  'is_subscriber',
  'is_trial',
  'is_account_disabled'
]

export function bulk_create_new_users_in_group(users, group_id) {
  return axios.post(`${USER_MANAGEMENT_BASE_URL}/group/${group_id}/create`, users, {headers: JSON_POST_HEADER})
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to create new user(s): ')
    })
}

export function fetch_user_permissions_summary() {
  return axios.get(`${USER_MANAGEMENT_BASE_URL}/permissions/summary`)
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error() )
    })
}

export function fetch_user_roles(user_id) {
  return axios.get(`${USER_MANAGEMENT_BASE_URL}/users/${user_id}/roles`)
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to fetch user roles' )
    })
}

export function fetch_user_roles__available(user_id) {
  return axios.get(`${USER_MANAGEMENT_BASE_URL}/users/${user_id}/roles/available`)
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to fetch available user roles' )
    })
}

export function add_user_roles(user_id, roles) {
  return axios.post(`${USER_MANAGEMENT_BASE_URL}/users/${user_id}/roles`, roles, {headers: JSON_POST_HEADER})
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to add user roles' )
    })
}

export function remove_user_roles(user_id, roles) {
  return axios.delete(`${USER_MANAGEMENT_BASE_URL}/users/${user_id}/roles`, { data: roles, headers: JSON_POST_HEADER })
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to remove user roles' )
    })
}

export function delete_user(user_id) {
  return axios.delete(`${USER_MANAGEMENT_BASE_URL}/users/${user_id}`)
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to delete user' )
    })
}

export function update_user(user_id, username, email, first_name, last_name) {
  const user = {
    username,
    email,
    firstName: first_name,
    lastName: last_name
  }
  return axios.put(`${USER_MANAGEMENT_BASE_URL}/users/${user_id}`, user, {headers: JSON_POST_HEADER})
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to update user' )
    })
}

export function send_set_password_email(user_id) {
  return axios.post(`${USER_MANAGEMENT_BASE_URL}/users/${user_id}/send_set_password_email`, {headers: JSON_POST_HEADER})
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to send set password email' )
    })
}
