import axios from 'axios'

import { TEXT_TO_NEAREST_GET_PRESIGNED_URL, TEXT_TO_NEAREST_SUBMIT_JOB } from '../../../constants/urls.js'
import { add_source_err_to_target_err } from '../../../utils/axios_utils.js'

export function get_presigned_url(folder_name, file_name) {
  return axios.post(TEXT_TO_NEAREST_GET_PRESIGNED_URL, { folder_name: folder_name, file_name: file_name })
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), `Unable to fetch presigned url for folder_name='${folder_name}' and file_name='${file_name}': `)
    })
}

export function submit_job(user_email, user_id, group_id, s3_input_folder, input_classifier_ids) {
  return axios.post(TEXT_TO_NEAREST_SUBMIT_JOB, { user_email, user_id, group_id, s3_input_folder, input_classifier_ids })
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to submit Batch job for text-to-nearest-service: ')
    })
}