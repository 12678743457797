export const HOME_PAGE = '/'

export const RUN_REPORT                    = '/run-report'
export const BUILD_REPORT                  = '/build-report'
export const CREATE_REPORT                 = '/create'
export const BUILD_ND_REPORT               = '/build-nd-report'
export const BUILD_VALUATION_REPORT        = '/build-valuation-report'
export const BUILD_CUSTOM_CLUSTERED_REPORT = '/build-custom-clustering-report'
export const BUILD_CLASSIFIER_ALERT_REPORT = '/build-classifier-alert-report'
export const BUILD_SIMILAR_FAMILIES_REPORT = '/build-similar-families-report'
export const KNN                           = '/knn'
export const SIMILAR_FAMILIES_SEARCH       = '/similar-families-search'
export const BUILD_CLASSIFIER_REPORT       = '/build-classifier-report'
export const BUILD_SET_THEORY_REPORT       = '/build-set-theory-report'
export const BUILD_UTT_LANDSCAPE_REPORT    = '/build-utt-landscape-report'
export const FAMILY                        = '/family'
export const DISPUTE                       = '/dispute'
export const HISTORY                       = '/history'
export const REPORT                        = '/report'
export const REPORT_STATE                  = '/goto'
export const NOTIFICATIONS                 = '/notifications'
export const SAVED                         = '/saved'
export const NOT_FOUND                     = '/page-not-found'
export const DOC_NOT_FOUND                 = '/document-not-found'
export const ADMIN                         = '/admin'
export const MONITOR                       = '/monitor'
export const REPORT_READER_QUERY           = '/rr-query'
export const SUBSCRIPTIONS                 = '/subscriptions'
export const REPORTS                       = '/reports'
export const CLASSIFIERS                   = '/classifiers'
export const TEXT_TO_NEAREST               = '/text-to-nearest'
export const LOGOUT                        = '/logout'
export const ASSIGNEE_GROUPING             = '/assignee-grouping'
export const ABOUT                         = '/about'
export const ICONS                         = '/icons'
export const HELP                          = '/help'
export const PATENT                        = '/patent'
export const TRENDS                        = '/trends'
export const CHART_SETS                    = '/chart-sets'
export const USER_SETTINGS                 = '/user-settings'
export const CREATE_USERS                  = '/create-users'
export const ROLE_OVERVIEW                 = '/role-overview'
export const BENCHMARKING                  = '/benchmarking'
export const SHARE_DECK                    = '/share_deck'
export const FTAGS_UI                      = '/family-tags'
export const SAVED_ORGS_LISTS_ADMIN        = '/saved-orgs-lists'
export const PROJECT                       = '/project'
export const REPORT_BASED_SUBSCRIPTIONS    = `${SUBSCRIPTIONS}/reports`
export const CLASSIFIER_SUBSCRIPTIONS      = `${SUBSCRIPTIONS}${CLASSIFIERS}`

export const SEARCH_GUIDE = 'search_guide'
export const CI_GUIDE = 'ci_guide'
export const REPORT_SCHEMA_GUIDE = 'report_schema_guide'

export const GRAPH = 'graph'
export const SOURCES = 'sources'

export const DOC   = 'doc'
export const IMAGE = 'image'

export const RR_REPORT_INPUT = 'metadata/report_input/report'
export const RR_REPORT_BUILD_STATUS = 'metadata/build_status/report'
export const RR_DATA_SUMMARY = 'metadata/data_summary/report'
export const RR_REPORT_COMBINED_SUMMARY = 'metadata/summary/report'