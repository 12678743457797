import React from 'react'
import cn from 'classnames'

import { SearchIcon } from '../widgets/IconSet.js'
import { InfoIcon } from '../widgets/IconSet.js'
import Tooltip from '../widgets/Tooltip.js'

import s from './TechExplorerInstructions.module.scss'

export const TECH_EXPLORER_INPUT_PLACEHOLDER = 'Example input:\ntennis racket'

const TechExplorerInstructions = () => {
  return (
    <div>
      <span className={s.heading_icon}><SearchIcon /></span>
      <span className='ms-2 my-auto'>
        Enter keywords, a piece of text, or application, publication or patent numbers.

        <Tooltip 
          toggler={<span className={cn('ms-1 my-auto', s.tooltip_trigger)}><InfoIcon /></span>}
          is_in_modal={true}
          placement='left'
        >
          <p>
            Search for patents related to your technology by entering any of the following input types:
          </p>
          <ul>            
            <li className={s.tooltip_list_item}>
              <div className={cn('fw-bolder')}>Keywords</div>
              <div>Use short phrases or terms that describe the technology.</div>
              <div>E.g.:</div>
              <div className={cn('fst-italic')}>tennis racket</div>
            </li>
            <li className={s.tooltip_list_item}>
              <div className={cn('fw-bolder')}>Piece of Text</div>
              <div>Paste a patent abstract, claims, description, or any text that describes the technology. To separate large pieces of text, leave an empty line between them.</div>
              <div>E.g.:</div>
              <div className={cn('fst-italic')}>A tennis racket which is made up of a handle, a throat and a head. The head of the racket is designed to increase power and control during play.</div>
            </li>
            <li className={s.tooltip_list_item}>
              <div className={cn('fw-bolder')}>Application, Publication, or Patent Numbers</div>
              <div>Enter application, publication, or patent numbers, separated by spaces, commas or semicolons.</div>
              <div>E.g.:</div>
              <div className={cn('fst-italic')}>EP0714681A1, EP2630997A1, EP2689813A1</div>
            </li>
          </ul>
        </Tooltip>
      </span>
    </div>
  )
}

export default TechExplorerInstructions